import { Welcome, Services } from './components';

export const Home = () => {
  return (
    <>
      <Welcome />
      <Services />
    </>
  );
};

export default Home;
